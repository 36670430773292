import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Contact</h1>
              <p>If you have any questions or queries feel free to call me me on <a href="tel:+44 0791 866 7054">+44 0791 866 7054</a> or fill out the form below and i'll get back to you.</p>
              <form
                name="contact"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                {/* Name */}
                <div className="field">
                  <label className="label" htmlFor={'name'}>
                    Your name*
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'name'}
                      onChange={this.handleChange}
                      id={'name'}
                      required={true}
                    />
                  </div>
                </div>
                {/* Phone */}
                <div className="field">
                  <label className="label" htmlFor={'phone'}>
                    Your number*
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'number'}
                      name={'phone'}
                      onChange={this.handleChange}
                      id={'phone'}
                      required={true}
                    />
                  </div>
                </div>
                {/* Email */}
                <div className="field">
                  <label className="label" htmlFor={'email'}>
                    Email*
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'email'}
                      name={'email'}
                      onChange={this.handleChange}
                      id={'email'}
                      required={true}
                    />
                  </div>
                </div>
                {/* Land Owners Name */}
                <div className="field">
                  <label className="label" htmlFor={'land-owner'}>
                    Land Owner's Name
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'land-owner'}
                      onChange={this.handleChange}
                      id={'land-owner'}
                      required={false}
                    />
                  </div>
                </div>
                {/* Brief description of work required */}
                <div className="field">
                  <label className="label" htmlFor={'work-required'}>
                    Brief description of work required*
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      name={'work-required'}
                      onChange={this.handleChange}
                      id={'work-required'}
                      required={true}
                    />
                  </div>
                </div>
                {/* Approximate date of work to start and finish */}
                <div className="field">
                  <label className="label" htmlFor={'date'}>
                    Approximate date of work to start and finish
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'date'}
                      onChange={this.handleChange}
                      id={'date'}
                      required={false}
                    />
                  </div>
                </div>
                {/* Any other relevant information */}
                <div className="field">
                  <label className="label" htmlFor={'other-info'}>
                    Any other relevant information
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      name={'other-info'}
                      onChange={this.handleChange}
                      id={'other-info'}
                      required={false}
                    />
                  </div>
                </div>
                <div className="field">
                  <button className="btn" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
